import * as React from "react";
import {useState, useEffect} from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import CleanDataParser from "../helpers/editorjs-parser";
import useIsClient from "../hooks/use-is-client";
import ClickReveal from "./click-reveal";
import {useFenixContext} from "../helpers/context";
import {Link} from "gatsby";
import TrustpilotMicro from "./trustpilot/trustpilot-micro";

function useVisits(pathname) {
  const [visits, setVisits] = useState(0);
  useEffect(() => {
    // may need to polyfill fetch, depending on browser
    fetch(
      process.env.FENIX_LOCATION_AJAX_URL +
      "_seo-scripts/av/?a=" +
      pathname
    )
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error('Fetch error');
      })
      .then((numVisits) => {
        setVisits(Number(numVisits));
      })
      .catch((error) => {
        //console.log(error)
      });
  }, [pathname]);

  return visits;
}

function Counter({pathname, value}) {
  const visits = useVisits(pathname);
  return <>{visits}</>;
}

export default function BlogContent(props: any) {

  const {isClient, key} = useIsClient();
  const {state, dispatch} = useFenixContext();
  const item = props.articleContent;
  const advisor = getImage(props.articleAdvisor?.photo2x?.localFile?.childImageSharp)

  const articleFullText = (
    <div>
      {item.fullContent?.data?.fullContent ? (
        JSON.parse(item.fullContent.data.fullContent).blocks.map(
          (block: any, idx: any) => CleanDataParser(block, idx)
        )
      ) : (
        <div/>
      )}
    </div>
  );
  const coverImage =
    item && item?.coverImage && item?.coverImage
      ? item?.coverImage?.localFile?.url
      : null;
  return (
    <div className="article-section">
      <div className={"article-container bg-white"}>
        <div className="article-env">
          {coverImage && (
            <GatsbyImage
              image={item?.coverImage?.localFile?.childImageSharp?.gatsbyImageData}
              alt={item?.title}
              className="article-featured"
            />
          )}
          <div className="article-content text-deepblue">
            <div className="article-header">
              <h1 className="text-center">{item?.title}</h1>
              <div className="meta-info d-flex flex-wrap flex-xl-row align-items-center justify-content-center">
                <span className="text-center d-block d-md-inline">
                  by <b>{item?.authorFullName}</b>
                </span>
                <span>
                  {item?.publishedAt ? item?.publishedAt : null}
                </span>
                <span className="view-counter">
                  <Counter
                    key={key}
                    value={
                      item?.viewCounter
                        ? item?.viewCounter
                        : 1000
                    }
                    pathname={item?.strapi_id}
                  />
                </span>
              </div>
            </div>
            <hr/>
            <div id={"articleText"} className={"article-text text-left"}>
              {articleFullText}
            </div>
            {item.displaySafeFooter &&
              <div className="article-footer">
                <div className="safe-cta">
                  <GatsbyImage
                    className="rounded-circle mb-4 mt-4" image={advisor}
                    alt={"Funeral Advisor - Fenix Funeral Directors"} loading="lazy"
                  />
                  <div style={{fontSize: "20px", lineHeight: "30px"}} className="">Melonie Johansson<br/>Funeral loan
                    advisor
                  </div>
                  <div className="d-flex flex-row align-items-center article-buttons">
                    <button
                      id="toggle-modal"
                      onClick={() => dispatch({type: "modalBooking"})}
                      data-toggle="modal"
                      data-target="#bookMeetingForm"
                      className="btn bg-transparent gtm-strongintent border-0 p-0 mt-0 mr-2 article-button underline font-weight-normal font"
                      title="Book consultation"
                    >
                      Book consultation
                    </button>
                    or <ClickReveal
                    className="ml-2 mt-0 bg-transparent border-none border-0 p-0 article-button underline font-weight-normal"
                  /></div>
                </div>
                <hr className="mx-auto"/>
                <div className="">
                  <h2 className="">About our partner Funeral Safe</h2>
                  <div className="text-wrap text-deepblue">
                    <div className="text-wrap">
                      Partnered with over 2,000 funeral homes and recommended by many more, Funeral Safe is exclusive in their dedication to funeral expense lending.<br/>
                      Funeral Safe is Authorised & Regulated by the FCA and, with their industry and financial expertise, you can rely on their simple, accessible and affordable finance for covering funeral costs.
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <Link to="/guidance/funeral-financing/funeral-loans/" title="Funeral safe"><img
                      src="/images/icon-safe.svg" alt="Safe logo" className="article-footer__safe"/></Link>
                    <div className="d-block d-lg-none">
                      <TrustpilotMicro
                        theme='light'
                        height={'50px'}
                      /></div>
                    <div className="d-none d-lg-block">
                      <TrustpilotMicro
                        theme='light'
                        height={'20px'}
                      /></div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};