import * as React from "react"
import {useEffect, useState} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {useStaticQuery, graphql, Link} from "gatsby"

import CleanDataParser from "../helpers/editorjs-parser";

export default function ArticlesGridRelative(props: any) {

  const {allStrapiArticle} = useStaticQuery(graphql`
      query {
          allStrapiArticle {
              nodes {
                  id
                  urlPart
                  titleShort
                  title
                  article_categories {
                      urlPart
                  }
                  parentCategory
                  parentCategorySlug
                  shortContent {
                      data {
                          id
                          shortContent
                      }
                  }
                  coverImage {
                      alternativeText
                      id
                      localFile {
                          childImageSharp {
                              gatsbyImageData(
                                  placeholder: NONE
                                  outputPixelDensities: 1
                              )
                          }
                          url
                      }
                  }
              }
          }
      }
  `)

  const [articles, setArticles] = useState<any>([]);
  const firstItem = [0];
  useEffect(() => {
    setArticles(allStrapiArticle.nodes)
  }, []);

  console.log(props.currentArticle)
  console.log(articles)

  console.log(articles.filter((article) => {return article.article_categories[0].urlPart === props.category && props.currentArticle !== article.urlPart}))

  const relevantArticles = articles.filter((article) => {return article.article_categories[0].urlPart === props.category && props.currentArticle !== article.urlPart}).length > 0 ? articles.filter((article) => {return article.article_categories[0].urlPart === props.category && props.currentArticle !== article.urlPart}) : articles;
  return (
    <div className="articles-section articles-section--relative pr-4 pl-4">
      <div className="block-wrapper">
        <div className="row no-gutters pb-2" style={stylesInline.minHeight}>
          <div className="col-12"><h2 className="mb-1 mb-xl-4 text-white text-left">More articles</h2></div>
        </div>
        <div className="row no-gutters justify-content-between">
          {relevantArticles && relevantArticles.slice(0, 5).map(function (item: any, i: any) {
              const articleShortText =
                <div>{item.shortContent && item.shortContent?.data?.shortContent && JSON.parse(item.shortContent.data.shortContent).blocks.map((block: any, idx: any) => CleanDataParser(block, idx))}</div>;
              const coverImage = item.coverImage ? item.coverImage.localFile.url : null;
              const itemSlug = item.parentCategorySlug !== null ? "/guidance/" + item.parentCategorySlug + "/" + item.urlPart + "/" : "/guidance/" + item.urlPart + "/";
              return <div key={i}
                          className={firstItem.indexOf(i) !== -1 ? "col-12 col-md-6 col-xl-8 article-col mb-4" : "col-12 col-md-6 col-xl-4 article-col mb-4"}>
                <Link to={itemSlug} state={{data: item, articles: props.data}}
                      className="follow-link d-block text-darkblue">
                  <div
                    className={firstItem.indexOf(i) !== -1 ? "article-wrapper article-wrapper--horizontal d-flex flex-column flex-xl-row bg-white" : "article-wrapper article-wrapper--vertical d-flex flex-column bg-white"}>
                    {coverImage && <GatsbyImage style={{height: "100%", overflow: "visible"}}
                                                image={item.coverImage.localFile.childImageSharp.gatsbyImageData}
                                                alt={item.titleShort ? item.titleShort : item.title} loading="lazy"/>}
                    <div className="article-content d-flex flex-column justify-content-between">
                      <div className="text-deepblue">
                        <h2
                          className="text-deepblue article-content__title mb-4">{item.titleShort ? item.titleShort : item.title}</h2>
                        <div>{articleShortText}</div>
                      </div>
                      <u>Read more</u>
                    </div>
                  </div>
                </Link>
              </div>
            }
          )}
        </div>
      </div>
    </div>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  minHeight: {
    minHeight: "unset",
  },
};